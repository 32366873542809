import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import EstudosPreliminares from "./components/EstudosPreliminares";

const ProjectDetails = () => {
  const tabs = [
    {
      label:'estudos preliminiares',
      element: <EstudosPreliminares />
    },
    {
      label:'anteprojeto',
      element: <></>
    },
    {
      label:'executivo',
      element: <></>
    },
    {
      label:'obra',
      element: <></>
    },
    {
      label:'financeiro',
      element: <></>
    },
    {
      label:'cadastro',
      element: <></>
    },
  ]

  return (
    <Grid
      item
      xs={12}
      md={7.9}
      component={Paper}
      p={1}
      bgcolor="background.dark"
      sx={{ minHeight: { xs: "6vh", md: "65vh" }, height:'100%' }}
    >
      <Box sx={{ }}>
        <Tabs
          centered
          variant="scrollable"
          textColor="rgba(255, 255, 255, 0.45)"
          indicatorColor="primary"
          value={0}
        >
          {tabs.map((tab, idx)=> (
          <Tab label={tab.label} active value={idx}/>
            
          ))}
        </Tabs>
      </Box>
      <Box>
        <EstudosPreliminares />
      </Box>
    </Grid>
  );
};

export default ProjectDetails;
