import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import MyTextField from "../../../components/MyTextField";
import Drawer from "../../../components/Drawer";
import { Close } from "@mui/icons-material";
import { createDocOnFirestore, getDocsFromFirestore } from "../../../assets/calls/firebase.calls";
import { useState } from "react";
import { useEffect } from "react";
import cep from "cep-promise";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../assets/configs/firebase.config";

const ProjectCreate = ({ open, setOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm();

  const onSubmit = async (data) => {
    const address = await cep(data.cep);
    // await addDoc(collection(db, "projects"), {...data, ...address});
    await createDocOnFirestore('projects', {...data, ...address});

    reset();
    setOpen(false);
  };

  const [clients, setClients] = useState([]);

  useEffect(() => {
    getDocsFromFirestore('clients').then(clients => setClients(clients.map(client => client.name)));
  }, [])

  const inputs = [
    {
      name: "name",
      label: "Nome do Projeto",
      required: true,
    },
    {
      name: "client",
      label: "Nome do Cliente",
      type:'select',
      options: clients,
      required: true,
    },
  ];

  const address = [
    {
      name: "cep",
      label: "CEP",
      required: true,
    },
    {
      name: "street",
      label: "Endereço",
      disabled: true,
      required: true,
    },
    {
      name: "number",
      label: "Número",
      required: true,
    },
    {
      name: "complement",
      label: "Complemento",
      required: true,
    },
    {
      name: "neighborhood",
      label: "Bairro",
      required: true,
      disabled: true,
    },
    {
      name: "city",
      label: "Cidade",
      required: true,
      disabled: true,
    },
    {
      name: "state",
      label: "Estado",
      required: true,
      disabled: true,
    },
  ];
  return (
    <Drawer state={open}>
      <Container>
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" mb={1}>
            Cadastro de Novo Projeto
          </Typography>
          <IconButton onClick={() => {setOpen(false); reset()}}>
            <Close />
          </IconButton>
        </Box>

        <Divider />

        <Grid container spacing={4} py={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" mb={1}>
              DADOS DO PROJETO
            </Typography>
            <MyTextField inputs={inputs} register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" mb={1}>
              ENDEREÇO DO PROJETO
            </Typography>
            <MyTextField inputs={address} register={register} errors={errors} setValue={setValue}/>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Box
              sx={{ gap: 1, my: 2, display: "flex", flexDirection: "column" }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Continuar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Drawer>
  );
};

export default ProjectCreate;
