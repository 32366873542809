import { Outlet, createBrowserRouter, useNavigate } from "react-router-dom";
import App from "./App";
import HomeIndex from "./pages/home";
import AuthIndex from "./pages/auth";
import Login from "./pages/auth/Login/Login";
import AppIndex from "./pages/app";
import Home from "./pages/app/home/Home";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./assets/configs/firebase.config";
import { Box, CircularProgress } from "@mui/material";
import ProjectDetails from "./pages/app/projectDetails/ProjectDetails";

const ProtectedRoute = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  setTimeout(() => {
    if (!loading) {
      if (!user?.uid) return navigate("/contas/login");
      return <Outlet />;
    }
  }, 500);

  return (
    <>
      {user?.uid ? (
        <Outlet />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "20vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export const routes = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    errorElement: <>Erro!</>,
    children: [
      {
        path: "*",
        exact: true,
        errorElement: <>Desculpe, algo deu errado!</>,
        children: [
          { path: "", element: <HomeIndex /> },
        ],
      },
      {
        path: '*',
        element: <ProtectedRoute />,
        children: [
          {
            path: "portal",
            errorElement: <>Algo deu errado!</>,
            element: <AppIndex />,
            children: [
              {
                path: "*",
                element: <Home />,
              },
              {
                path: "projetos",
                element: <Outlet/>,
                children: [{ path: ":projectId", element: <ProjectDetails /> }],
              },
            ],
          },
        ]
      },
      {
        path: "contas",
        element: <AuthIndex />,
        children: [{ path: "login", element: <Login /> }],
      },
    ],
  },
]);
