import { Add } from "@mui/icons-material";
import { Box, IconButton, MenuItem, MenuList, Typography } from "@mui/material";
import React, { useState } from "react";

const EstudosPreliminares = () => {
  const [rootFolders, setRootFolders] = useState([
    {
      label: "propostas",
    },
    {
      label: "contratos",
    },
    {
      label: "levantamentos técnicos",
    },
    {
      label: "briefing",
    },
  ]);

  return (
    <Box sx={{ p: 1, maxHeight:'60vh', overflow:'auto' }}>
      {/* <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <IconButton><Add/></IconButton>
        </Box> */}

      <Box sx={{ bgcolor: "#22222" }}>
        <MenuList>
          {rootFolders.map((folder) => (
            <>
              <MenuItem>{folder.label}</MenuItem>
              <Box sx={{ minHeight: "40vh" }}></Box>
            </>
          ))}
        </MenuList>
      </Box>
    </Box>
  );
};

export default EstudosPreliminares;
