import { Box, Container, Typography } from "@mui/material";
import React from "react";

const HomeContact = () => {
  return (
    <div id="contato">
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: 0,
                mb: 1.5,
              }}
            >
              <Typography variant="h1" fontWeight={400} color="fonts.primary">
                contato
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </Container>
        <Box sx={{ bgcolor: "background.dark", width: "100%" }}>
          <Container
            maxWidth="xl"
            sx={{
              bgcolor: "background.dark",
              width: "100%",
              minHeight: "50vh",
              alignItems: "flex-end",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              color: "fonts.primary",
            }}
          >
            {/* <Typography variant="h4">Rio de Janeiro</Typography> */}
            <Typography variant="h4" sx={{ cursor: "pointer" }}
                  onClick={() => window.open("https://api.whatsapp.com/send?phone=5521964896308&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20escrit%C3%B3rio.", "_blank")}>
              21 9 6489-6308
            </Typography>
            <Typography variant="h4" 
            sx={{ cursor: "pointer" }}
            onClick={() =>
              window.open("mailto://contato@panemaarquitetura.com.br", "_self")
            }>contato@panemaarquitetura.com.br</Typography>
            <Typography variant="h4" sx={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://instagram.com/panemaarquitetura", "_blank")
                  }>@panemaarquitetura</Typography>
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default HomeContact;
