import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import ClientRegistration from "./forms/ClientCreate";
import { useEffect } from "react";

import { getDocsFromFirestore } from "../../assets/calls/firebase.calls";

const ClientsList = () => {
  const [clients, setClients] = useState([]);
  const [craeteOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    getDocsFromFirestore("clients").then((clients) => setClients(clients));
  }, [craeteOpen]);

  return (
    <Grid
      item
      xs={12}
      md={3.9}
      component={Paper}
      bgcolor="background.dark"
      sx={{ minHeight: { xs: "6vh", md: "65vh" } }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          bgcolor: "background.dark",
          alignItems: "center",
          minHeight: "7vh",
          p: 1,
        }}
      >
        <Typography variant="h4" color="fonts.primary">
          clientes
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            color: "fonts.primary",
          }}
        >
          <IconButton
            sx={{
              bgcolor: "primary.main",
              color: "rgba(255, 255, 255, 0.45)",
            }}
            onClick={() => setCreateOpen(true)}
          >
            <Add fontSize="small" />
          </IconButton>

          <ClientRegistration open={craeteOpen} setOpen={setCreateOpen} />
          <IconButton
            sx={{
              bgcolor: "primary.main",
              color: "rgba(255, 255, 255, 0.45)",
            }}
          >
            <Search fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "inline" },
          width: "100%",
          height: "100%",
          overflow: "auto",
          maxHeight: "60vh",
          pr: 1,
        }}
      >
        <MenuList dense="dense">
          {clients.map((client) => (
            <Box
              bgcolor="background.dark"
              sx={{ width: "100%", borderRadius: "10px" }}
            >
              <MenuItem>
                <ListItemAvatar>
                  <Avatar
                    src="https://www.figma.com/file/YaDHCDXT7VxqQdSQR6mkyg/Untitled?type=design&node-id=11-546&mode=design&t=n9FRqokNiPMGmY7z-4"
                    width={33}
                    height={33}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="subtitle1" color="fonts.primary">
                    {client.name}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Box>
          ))}
        </MenuList>
      </Box>
    </Grid>
  );
};

export default ClientsList;
