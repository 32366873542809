import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../configs/firebase.config";

const getDocsFromFirestore = async (reference) => {
  const data = [];
  const querySnapshot = await getDocs(collection(db, reference))
  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

const createDocOnFirestore = async (reference, data) => {
    const ref = collection(db, reference);
    await addDoc(ref, data);

    return ref.id;
}

export { getDocsFromFirestore, createDocOnFirestore };
