import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import MyTextField from "../../../components/MyTextField";
import Drawer from "../../../components/Drawer";
import { Close } from "@mui/icons-material";
import { db } from "../../../assets/configs/firebase.config";
import { addDoc, collection } from "firebase/firestore";
import cep from "cep-promise";
import { createDocOnFirestore } from "../../../assets/calls/firebase.calls";

const ClientRegistration = ({ open, setOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const address = await cep(data.cep);
    await createDocOnFirestore('clients', {...data, ...address});

    reset();
    setOpen(false);
  };

  const inputs = [
    {
      name: "name",
      label: "Nome Completo",
      required: true,
    },
    {
      name: "cpf",
      label: "Número do CPF",
    },
    {
      name: "rg",
      label: "Número do RG",
    },
    {
      name: "birthday",
      label: "Data de Nascimento",
    },
    {
      name: "cellNumber",
      label: "Telefone Celular",
      required: true,
    },
    {
      name: "phoneNumber",
      label: "Telefone Fixo",
    },
    {
      name: "email",
      label: "Endereço de E-mail",
      required: true,
    },
  ];

  const address = [
    {
      name: "cep",
      label: "CEP",
      required: true,
    },
    {
      name: "street",
      label: "Endereço",
      disabled: true,
      required: true,
    },
    {
      name: "number",
      label: "Número",
      required: true,
    },
    {
      name: "complement",
      label: "Complemento",
      required: true,
    },
    {
      name: "neighborhood",
      label: "Bairro",
      required: true,
      disabled: true,
    },
    {
      name: "city",
      label: "Cidade",
      required: true,
      disabled: true,
    },
    {
      name: "state",
      label: "Estado",
      required: true,
      disabled: true,
    },
  ];

  // const handleClose = () => {
  //   setOpen(false);
  //   reset();
  // };
  return (
    <Drawer state={open}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" mb={1}>
            Cadastro de Novo Cliente
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>

        <Divider />

        <Grid container spacing={4} py={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" mb={1}>
              DADOS DO CLIENTE
            </Typography>
            <MyTextField inputs={inputs} register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" mb={1}>
              ENDEREÇO PRINCIPAL
            </Typography>
            <MyTextField inputs={address} register={register} errors={errors} setValue={setValue} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Box
              sx={{ gap: 1, my: 2, display: "flex", flexDirection: "column" }}
            >
              <FormControlLabel
                required
                control={<Checkbox value="remember" color="primary" />}
                label="Aceita receber atualizaçoes e comunidados via Whatsapp."
              />
              <FormControlLabel
                required
                control={<Checkbox value="remember" color="primary" />}
                label="Aceita receber atualizações e comunicados via e-mail."
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Continuar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Drawer>
  );
};

export default ClientRegistration;
