import React from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../assets/configs/firebase.config";
import { Home, Notifications } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PageTitle = ({ title, subtitle, description }) => {
  const navigate = useNavigate();


  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ display: "flex", width: "100%", mt: 2 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h3" fontWeight={400} color="fonts.primary">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {subtitle && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={400}
                    color="fonts.primary"
                  >
                    {subtitle}
                  </Typography>
                  <Box sx={{display:'flex', alignItems:'center'}}>
                    <IconButton>
                      <Home onClick={() => navigate('/portal')} />
                    </IconButton>
                    <IconButton>
                      <Notifications />
                    </IconButton>
                    <Divider />
                    <IconButton onClick={() => signOut(auth)}>
                      <Avatar src="" />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>

        {description && (
          <Box>
            <Typography variant="h6" sx={{ color: "fonts.primary" }}>
              {description}
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default PageTitle;
