import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import { theme } from "./assets/theme";
import Navbar from "./components/Navbar";
import {useEffect} from 'react'

function App() {

  useEffect(() => {
    console.log(process.env)
  
  }, [])
  
  return (
    <Box
      sx={{ minHeight: "100vh", bgcolor: "background.dark", height: "100%" }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />

        <Outlet />
      </ThemeProvider>
    </Box>
  );
}

export default App;
