import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PageTitle from "../../../components/PageTitle";
import MyTextField from "../../../components/MyTextField";
import { useForm } from "react-hook-form";
import { auth } from "../../../assets/configs/firebase.config";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = ({ menuOpen, handleOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(false);

  const inputs = [
    {
      label: "Email",
      size: 12,
      name: "email",
      type: "email",
      required: true,
      helperText: "O endereço de email é obrigatório.",
    },
    {
      label: "Senha",
      name: "password",
      type: "password",
      required: true,
      size: 12,
      helperText: "A senha é obrigatória.",
    },
  ];

  const onSubmit = (data) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((user) => {
        user && navigate("/portal/inicio");
      })
      .catch((e) => {
        let message;
        console.log(e.message);
        if (e.message === "Firebase: Error (auth/user-not-found).")
          message = "Ops... E-mail não encontrado na base de dados.";
        if (e.message === "Firebase: Error (auth/wrong-password).")
          message = "Ops... Senha incorreta.";
        else message = "O usuário e/ou senha errada. Tente novamente.";
        setErrorMessage(message);
      });
  };

  return (
    <Box
      sx={{
        minHeight: "calc(100vh-85px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageTitle
        title="portal do projeto"
        description="tenha acesso a todos os conteúdos referentes ao seu projeto"
        menuOpen={menuOpen}
        handleOpen={handleOpen}
      />

      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          mt: 8,
          maxWidth: 500,
        }}
      >
        <Paper
          sx={{
            bgcolor:'background.dark',
            borderColor: "rgba(255, 255, 255, 0.45)",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            p: 4,
            zIndex:99
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              login
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <MyTextField
                inputs={inputs}
                register={register}
                errors={errors}
              />
              {errorMessage && (
                <Typography color="error" mt={1}>
                  {errorMessage}
                </Typography>
              )}
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                sx={{ mt: 3, mb: 2 }}
              >
                entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Esqueceu a senha?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
      {/* <Box
        sx={{
          position: "absolute",
          bottom: 10,
          width: "100%",
          maxWidth: "100%",
          height: '45vh',
          maxHeight: '100%'
        }}
      >
        <img
          src={require("./fundo_portal_projetos.png")}
          style={{
            objectFit: "cover",
            opacity:'50%',
            width: "100%",
            maxWidth: "100%",
            height: '100%',
            zIndex: -1
          }}
        />
      </Box> */}
    </Box>
  );
};

export default Login;
